const breakpointDefs = {
    large: 1024
};

function isLargeBreakpoint() {
    return window.matchMedia(`(min-width: ${breakpointDefs.large}px)`).matches;
}

module.exports = {
    breakpointDefs,
    isLargeBreakpoint
};
